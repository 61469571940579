<template>
  <div
    class="h-screen flex items-center justify-center justify-items-center bg-gray-100"
  >
    <div
      class="border border-gray-200 bg-white text-black w-96 h-60 rounded-md shadow-sm p-5 flex flex-col justify-around items-center"
    >
      <LogoAmoba class="w-36" />

      <h1 class="text-xl font-medium text-gray-600">Iniciar sesíon</h1>
      <button
        @click="loginWhitGoogle"
        class="border border-gray-200 pl-2 pr-4 font-medium text-gray-500 rounded flex items-center justify-center hover:shadow-md hover:bg-gray-100 hover:text-gray-600 focus:outline-none active:shadow-none"
      >
        <GGoogle />
        Usar cuenta de Google
      </button>
    </div>
  </div>
</template>

<script>
import LogoAmoba from '@/components/LogoAmoba.vue'
import GGoogle from '@/components/icons/GGoogle.vue'
import { useStore } from 'vuex'

import { LOGIN_WITH_GOOGLE } from '@/store/modules/auth/actions.type'
import { AUTH_STORE_NAME } from '@/store//modules/auth/index.js'
import { computed } from 'vue'

export default {
  components: {
    LogoAmoba,
    GGoogle
  },
  setup() {
    const store = useStore()
    return {
      loginWhitGoogle: () =>
        store.dispatch(`${AUTH_STORE_NAME}/${LOGIN_WITH_GOOGLE}`),
      authUserData: computed(() => store.getters['auth/authUserData'])
    }
  }
}
</script>
