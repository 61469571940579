<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1076.691 356.214"
    enable-background="new 0 0 1076.691 356.214"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="primary"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M183.4,199.589
		c5.547-3.3,9.97-8.163,14.182-13.053c24.924-28.936,45.612-61.514,61.201-96.378c3.564-7.971,6.896-16.177,8.022-24.836
		c1.127-8.659-0.152-17.92-5.161-25.072c-7.439-10.62-21.197-14.492-33.975-16.696c-26.677-4.601-54.05-5.141-80.888-1.596
		c-14.732,1.946-30.885,6.16-38.842,18.71c-6.673,10.525-5.678,24.037-3.802,36.357c5.118,33.611,15.072,66.482,29.46,97.286
		c3.065,6.561,6.412,13.148,11.499,18.302C154.261,201.902,171.864,206.453,183.4,199.589z"
      />
      <path
        opacity="0.96"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :fill="secondary"
        d="M201.886,184.136
		c-4.028,5.287-9.522,9.241-15.02,12.976c-32.531,22.101-68.292,39.441-105.791,51.297c-8.574,2.711-17.36,5.155-26.351,5.291
		c-8.991,0.136-18.316-2.26-25.045-8.225c-9.992-8.858-12.338-23.389-13.092-36.721c-1.575-27.833,1.087-55.903,7.866-82.944
		c3.721-14.843,9.929-30.875,23.703-37.542c11.552-5.591,25.259-2.986,37.643,0.38c33.786,9.183,66.247,23.227,96.073,41.565
		c6.353,3.906,12.698,8.104,17.375,13.914C207.674,154.595,210.263,173.139,201.886,184.136z"
      />
      <path
        id="XMLID_13_"
        opacity="0.95"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :fill="accent"
        d="M197.569,172.734
		c4.165,4.094,7.006,9.32,9.656,14.525c15.679,30.798,26.85,63.885,33.049,97.884c1.417,7.773,2.579,15.703,1.706,23.556
		s-3.99,15.718-9.933,20.926c-8.825,7.734-21.751,8.176-33.456,7.362c-24.438-1.698-48.614-7.115-71.44-16.007
		c-12.529-4.881-25.82-12.062-30.113-24.805c-3.6-10.687,0.183-22.349,4.484-32.774c11.733-28.441,27.556-55.19,46.833-79.168
		c4.106-5.107,8.466-10.176,14.046-13.612C172.454,164.43,188.906,164.218,197.569,172.734z"
      />
    </g>
    <g>
      <path
        :fill="titleColor"
        d="M386.138,234.74c0.518,1.295,0.755,2.353,0.712,3.171c-0.044,0.821-0.346,1.446-0.905,1.877
		c-0.563,0.431-1.424,0.712-2.589,0.841c-1.165,0.13-2.653,0.194-4.465,0.194c-1.813,0-3.258-0.064-4.336-0.194
		c-1.08-0.129-1.921-0.323-2.523-0.582c-0.604-0.259-1.058-0.603-1.359-1.035c-0.303-0.431-0.582-0.948-0.841-1.554l-16.049-44.65
		h-73.512l-15.401,44.392c-0.174,0.605-0.433,1.123-0.776,1.554c-0.346,0.433-0.821,0.799-1.424,1.1
		c-0.604,0.302-1.445,0.538-2.523,0.712c-1.08,0.172-2.438,0.259-4.077,0.259c-1.812,0-3.322-0.087-4.529-0.259
		c-1.209-0.174-2.093-0.476-2.653-0.906c-0.563-0.431-0.863-1.035-0.906-1.812c-0.044-0.776,0.194-1.812,0.712-3.106l58.629-159.318
		c0.259-0.689,0.646-1.271,1.164-1.747c0.518-0.474,1.186-0.842,2.007-1.101c0.818-0.259,1.812-0.452,2.977-0.582
		c1.164-0.129,2.479-0.194,3.947-0.194c1.553,0,2.912,0.065,4.076,0.194c1.165,0.13,2.156,0.323,2.978,0.582
		c0.818,0.259,1.466,0.627,1.941,1.101c0.473,0.476,0.841,1.058,1.1,1.747L386.138,234.74z M316.639,89.529h-0.13l-31.579,90.077
		h64.064L316.639,89.529z"
      />
      <path
        :fill="titleColor"
        d="M591.532,237.457c0,0.519-0.129,0.993-0.389,1.424c-0.259,0.433-0.711,0.776-1.358,1.035
		c-0.647,0.26-1.468,0.474-2.459,0.647c-0.993,0.172-2.265,0.259-3.818,0.259c-1.381,0-2.588-0.087-3.624-0.259
		c-1.035-0.174-1.855-0.388-2.459-0.647c-0.604-0.259-1.035-0.603-1.294-1.035c-0.259-0.431-0.388-0.905-0.388-1.424V86.422h-0.259
		l-65.876,151.941c-0.174,0.346-0.433,0.67-0.777,0.971c-0.346,0.304-0.82,0.563-1.423,0.776c-0.604,0.215-1.316,0.389-2.136,0.518
		c-0.821,0.13-1.792,0.194-2.912,0.194c-1.209,0-2.244-0.064-3.106-0.194c-0.863-0.129-1.575-0.303-2.135-0.518
		c-0.563-0.214-1.016-0.473-1.359-0.776c-0.346-0.301-0.563-0.625-0.647-0.971l-62.64-151.941h-0.13v151.035
		c0,0.519-0.129,0.993-0.388,1.424c-0.259,0.433-0.712,0.776-1.359,1.035c-0.646,0.26-1.468,0.474-2.459,0.647
		c-0.993,0.172-2.31,0.259-3.947,0.259c-1.468,0-2.697-0.087-3.688-0.259c-0.993-0.174-1.792-0.388-2.395-0.647
		c-0.604-0.259-1.015-0.603-1.229-1.035c-0.216-0.431-0.323-0.905-0.323-1.424V80.728c0-3.106,0.818-5.242,2.459-6.406
		c1.638-1.165,3.235-1.748,4.788-1.748h8.542c2.156,0,4.055,0.239,5.695,0.712c1.638,0.476,3.083,1.165,4.335,2.071
		c1.25,0.905,2.308,2.006,3.171,3.3c0.861,1.295,1.638,2.763,2.33,4.4l55.263,133.046h0.647l57.593-132.528
		c0.861-2.07,1.79-3.817,2.782-5.241c0.991-1.424,2.026-2.566,3.106-3.43c1.078-0.861,2.242-1.466,3.494-1.812
		c1.25-0.344,2.609-0.519,4.077-0.519h9.447c0.777,0,1.596,0.13,2.459,0.389c0.862,0.259,1.639,0.712,2.33,1.359
		c0.689,0.646,1.272,1.488,1.747,2.523c0.474,1.035,0.712,2.329,0.712,3.883V237.457z"
      />
      <path
        :fill="titleColor"
        d="M775.182,154.368c0,13.375-1.533,25.477-4.595,36.303c-3.063,10.829-7.658,20.061-13.783,27.697
		c-6.128,7.636-13.849,13.524-23.167,17.666s-20.189,6.212-32.614,6.212s-23.102-1.964-32.032-5.889
		c-8.93-3.925-16.265-9.533-22.001-16.825c-5.739-7.29-9.966-16.197-12.684-26.726c-2.718-10.525-4.077-22.39-4.077-35.591
		c0-13.114,1.509-25.021,4.53-35.721c3.019-10.697,7.613-19.821,13.783-27.373c6.168-7.549,13.891-13.395,23.166-17.536
		c9.274-4.142,20.168-6.213,32.68-6.213c12.165,0,22.691,1.941,31.579,5.824c8.886,3.883,16.242,9.448,22.066,16.695
		c5.824,7.248,10.137,16.049,12.942,26.402C773.779,129.649,775.182,141.341,775.182,154.368z M758.357,155.792
		c0-9.921-0.928-19.219-2.782-27.891c-1.856-8.671-4.918-16.22-9.189-22.648c-4.271-6.427-9.945-11.475-17.019-15.143
		c-7.076-3.666-15.834-5.5-26.272-5.5c-10.354,0-19.09,1.921-26.208,5.759c-7.119,3.84-12.9,8.995-17.343,15.466
		c-4.445,6.472-7.636,14.022-9.577,22.649c-1.941,8.629-2.912,17.73-2.912,27.308c0,10.182,0.884,19.652,2.653,28.408
		c1.767,8.758,4.766,16.372,8.994,22.843c4.227,6.472,9.879,11.541,16.955,15.207c7.073,3.669,15.918,5.501,26.531,5.501
		c10.525,0,19.369-1.962,26.531-5.889c7.161-3.926,12.942-9.189,17.343-15.79s7.549-14.236,9.447-22.907
		C757.407,174.493,758.357,165.369,758.357,155.792z"
      />
      <path
        :fill="titleColor"
        d="M918.322,193.196c0,4.918-0.582,9.492-1.747,13.719c-1.165,4.229-2.827,8.068-4.982,11.519
		c-2.158,3.452-4.789,6.516-7.896,9.188c-3.105,2.676-6.622,4.94-10.548,6.795c-3.927,1.856-8.176,3.258-12.748,4.206
		c-4.574,0.95-9.88,1.424-15.919,1.424h-43.356c-1.553,0-3.15-0.582-4.788-1.747c-1.641-1.165-2.459-3.301-2.459-6.406V80.728
		c0-3.106,0.818-5.242,2.459-6.406c1.638-1.165,3.235-1.748,4.788-1.748h35.979c9.749,0,17.645,0.993,23.685,2.978
		c6.038,1.985,11.021,4.811,14.948,8.477c3.925,3.669,6.859,8.111,8.801,13.331c1.941,5.221,2.912,11.022,2.912,17.407
		c0,3.97-0.453,7.765-1.359,11.389c-0.905,3.624-2.265,6.989-4.076,10.095c-1.813,3.106-4.077,5.911-6.795,8.413
		c-2.718,2.503-5.934,4.616-9.643,6.342c4.485,0.863,8.714,2.48,12.684,4.853c3.968,2.374,7.442,5.371,10.419,8.995
		s5.326,7.83,7.054,12.619C917.459,182.259,918.322,187.501,918.322,193.196z M890.626,115.801c0-4.4-0.604-8.391-1.812-11.972
		c-1.21-3.579-3.129-6.643-5.76-9.189c-2.633-2.544-6.063-4.507-10.289-5.889c-4.229-1.379-9.881-2.07-16.954-2.07h-26.144v60.052
		H858.4c6.297,0,11.389-0.841,15.271-2.523s7.073-3.947,9.577-6.795c2.502-2.848,4.355-6.147,5.565-9.9
		C890.021,123.76,890.626,119.858,890.626,115.801z M901.497,194.49c0-5.521-0.886-10.396-2.652-14.625
		c-1.77-4.227-4.358-7.765-7.766-10.612c-3.409-2.848-7.616-5.003-12.619-6.471c-5.005-1.467-11.217-2.2-18.637-2.2h-30.155v65.357
		h36.497c5.349,0,10.073-0.667,14.172-2.006c4.097-1.337,7.721-3.32,10.871-5.953c3.149-2.631,5.65-5.909,7.507-9.836
		C900.569,204.218,901.497,199.666,901.497,194.49z"
      />
      <path
        :fill="titleColor"
        d="M1072.076,234.74c0.518,1.295,0.755,2.353,0.712,3.171c-0.044,0.821-0.346,1.446-0.905,1.877
		c-0.563,0.431-1.424,0.712-2.589,0.841c-1.165,0.13-2.653,0.194-4.465,0.194c-1.813,0-3.258-0.064-4.336-0.194
		c-1.08-0.129-1.921-0.323-2.523-0.582c-0.604-0.259-1.058-0.603-1.359-1.035c-0.303-0.431-0.582-0.948-0.841-1.554l-16.049-44.65
		h-73.512l-15.401,44.392c-0.174,0.605-0.433,1.123-0.776,1.554c-0.346,0.433-0.821,0.799-1.424,1.1
		c-0.604,0.302-1.445,0.538-2.523,0.712c-1.08,0.172-2.438,0.259-4.077,0.259c-1.812,0-3.322-0.087-4.529-0.259
		c-1.209-0.174-2.093-0.476-2.653-0.906c-0.563-0.431-0.863-1.035-0.906-1.812c-0.044-0.776,0.194-1.812,0.712-3.106l58.629-159.318
		c0.259-0.689,0.646-1.271,1.164-1.747c0.518-0.474,1.186-0.842,2.007-1.101c0.818-0.259,1.812-0.452,2.977-0.582
		c1.164-0.129,2.479-0.194,3.947-0.194c1.553,0,2.912,0.065,4.076,0.194c1.165,0.13,2.156,0.323,2.978,0.582
		c0.818,0.259,1.466,0.627,1.941,1.101c0.473,0.476,0.841,1.058,1.1,1.747L1072.076,234.74z M1002.577,89.529h-0.13l-31.579,90.077
		h64.065L1002.577,89.529z"
      />
    </g>
    <g>
      <path
        :fill="subTitleColor"
        d="M289.742,277.011l-3.784,1.12c-1.11-4.561-3.733-6.842-7.871-6.842c-3.047,0-5.398,1.292-7.053,3.875
		c-1.453,2.301-2.18,5.358-2.18,9.173c0,4.439,0.948,7.83,2.846,10.171c1.614,1.998,3.743,2.997,6.387,2.997
		c4.359,0,7.094-2.32,8.204-6.963l3.693,1.241c-1.938,5.914-5.903,8.87-11.897,8.87c-4.278,0-7.618-1.645-10.02-4.935
		c-2.119-2.886-3.179-6.64-3.179-11.261c0-5.288,1.382-9.435,4.147-12.442c2.381-2.583,5.398-3.875,9.051-3.875
		c3.048,0,5.601,0.848,7.659,2.543C287.623,272.198,288.955,274.307,289.742,277.011z"
      />
      <path
        :fill="subTitleColor"
        d="M310.266,268.141c4.138,0,7.427,1.635,9.869,4.904c2.18,2.927,3.27,6.67,3.27,11.231
		c0,5.287-1.403,9.444-4.208,12.472c-2.382,2.604-5.379,3.905-8.991,3.905c-4.197,0-7.507-1.654-9.929-4.965
		c-2.14-2.906-3.209-6.649-3.209-11.23c0-5.288,1.393-9.435,4.178-12.442C303.627,269.433,306.635,268.141,310.266,268.141z
		 M310.266,271.289c-3.067,0-5.418,1.292-7.053,3.875c-1.453,2.28-2.18,5.338-2.18,9.173c0,4.439,0.928,7.83,2.785,10.171
		c1.614,1.998,3.743,2.997,6.387,2.997c3.048,0,5.398-1.302,7.054-3.905c1.453-2.301,2.18-5.389,2.18-9.263
		c0-4.38-0.929-7.729-2.785-10.051C315.04,272.288,312.911,271.289,310.266,271.289z"
      />
      <path
        :fill="subTitleColor"
        d="M357.401,253.883v46.014h-3.724v-4.783c-2.301,3.693-5.419,5.54-9.354,5.54
		c-3.895,0-7.003-1.645-9.323-4.935c-2.1-2.967-3.148-6.781-3.148-11.442c0-5.308,1.321-9.445,3.966-12.412
		c2.199-2.482,4.975-3.724,8.324-3.724c3.875,0,7.014,1.807,9.415,5.419v-19.677H357.401z M353.556,277.707
		c-2.18-4.137-5.116-6.206-8.81-6.206c-2.865,0-5.105,1.252-6.72,3.754c-1.474,2.261-2.21,5.288-2.21,9.082
		c0,4.379,0.999,7.739,2.997,10.08c1.635,1.958,3.713,2.937,6.235,2.937c3.391,0,6.227-2.008,8.507-6.024V277.707z"
      />
      <path
        :fill="subTitleColor"
        d="M393.971,284.7h-22.492c0,4.339,1.049,7.679,3.148,10.02c1.756,1.938,3.996,2.906,6.72,2.906
		c4.46,0,7.387-2.22,8.779-6.66l3.603,1.362c-2.14,5.551-6.287,8.325-12.442,8.325c-4.339,0-7.76-1.645-10.262-4.935
		c-2.221-2.906-3.33-6.66-3.33-11.261c0-5.247,1.423-9.395,4.269-12.442c2.401-2.583,5.398-3.875,8.99-3.875
		c4.097,0,7.356,1.585,9.778,4.753c2.159,2.825,3.239,6.428,3.239,10.808V284.7z M390.126,281.794
		c-0.283-3.593-1.332-6.316-3.148-8.174c-1.554-1.594-3.542-2.392-5.964-2.392c-2.784,0-5.045,1.08-6.78,3.239
		c-1.534,1.877-2.412,4.319-2.634,7.326H390.126z"
      />
      <path
        :fill="subTitleColor"
        d="M421.095,276.103c1.856-5.308,5.923-7.962,12.199-7.962c7.326,0,10.989,3.432,10.989,10.293v10.928
		c0,4.138,0.252,7.649,0.757,10.535h-3.603c-0.263-1.19-0.494-3.007-0.696-5.449c-2.725,4.138-6.458,6.206-11.2,6.206
		c-3.29,0-5.803-0.948-7.538-2.846c-1.474-1.594-2.21-3.643-2.21-6.146c0-5.61,3.491-9.001,10.475-10.171
		c2.724-0.465,6.154-0.797,10.292-0.999v-1.665c0-2.946-0.646-4.995-1.938-6.146c-1.13-1.009-2.926-1.514-5.389-1.514
		c-4.439,0-7.285,2.079-8.536,6.236L421.095,276.103z M440.559,283.338c-3.108,0.061-6.267,0.394-9.476,0.999
		c-4.964,1.009-7.446,3.39-7.446,7.144c0,2.019,0.646,3.572,1.938,4.662c1.15,0.989,2.694,1.483,4.632,1.483
		c4.157,0,7.607-2.24,10.353-6.721V283.338z"
      />
      <path
        :fill="subTitleColor"
        d="M460.298,268.898v5.752c3.471-4.339,7.245-6.509,11.321-6.509c2.806,0,4.995,0.878,6.569,2.634
		c1.412,1.555,2.119,3.593,2.119,6.115v23.007h-3.905v-21.402c0-4.581-1.917-6.872-5.752-6.872c-3.592,0-7.003,2.149-10.231,6.448
		v21.826h-3.845v-30.999H460.298z"
      />
      <path
        :fill="subTitleColor"
        d="M514.425,268.898l-11.716,32.604c-1.312,3.713-2.946,6.307-4.903,7.78c-1.615,1.23-3.644,1.847-6.085,1.847
		c-1.029,0-1.958-0.081-2.785-0.242l-0.303-3.36c1.109,0.242,2.139,0.363,3.088,0.363c1.896,0,3.44-0.616,4.632-1.847
		c1.069-1.07,2.048-2.806,2.937-5.207l0.575-1.483l-11.05-30.454h4.147l6.721,19.979c0.403,1.231,1.019,3.311,1.847,6.236h0.302
		c0.303-0.867,0.677-2.018,1.12-3.451c0.465-1.433,0.777-2.411,0.938-2.937l6.569-19.828H514.425z"
      />
      <path
        :fill="subTitleColor"
        d="M528.441,257.787v11.11h7.81v3.088h-7.81v20.161c0,3.653,1.533,5.479,4.601,5.479
		c1.332,0,2.675-0.151,4.026-0.454l-0.272,3.148c-1.291,0.223-2.755,0.333-4.39,0.333c-5.206,0-7.81-2.583-7.81-7.75v-20.918h-5.328
		v-3.088h5.328v-10.232L528.441,257.787z"
      />
      <path
        :fill="subTitleColor"
        d="M549.632,256.425v5.782h-5.691v-5.782H549.632z M548.723,268.898v30.999h-3.845v-30.999H548.723z"
      />
      <path
        :fill="subTitleColor"
        d="M564.798,268.898v5.752c3.229-4.339,6.72-6.509,10.474-6.509c2.261,0,4.157,0.687,5.691,2.059
		c1.332,1.171,2.119,2.634,2.361,4.39c3.148-4.299,6.66-6.448,10.535-6.448c2.725,0,4.854,0.878,6.388,2.634
		c1.372,1.555,2.058,3.593,2.058,6.115v23.007h-3.844v-21.402c0-4.581-1.888-6.872-5.661-6.872c-1.716,0-3.462,0.666-5.237,1.998
		c-1.494,1.131-2.826,2.613-3.996,4.45v21.826h-3.845v-21.402c0-4.581-1.816-6.872-5.449-6.872c-1.715,0-3.44,0.636-5.176,1.907
		c-1.555,1.11-2.947,2.624-4.178,4.541v21.826h-3.784v-30.999H564.798z"
      />
      <path
        :fill="subTitleColor"
        d="M638.783,284.7h-22.492c0,4.339,1.049,7.679,3.148,10.02c1.756,1.938,3.996,2.906,6.72,2.906
		c4.46,0,7.387-2.22,8.779-6.66l3.603,1.362c-2.14,5.551-6.287,8.325-12.442,8.325c-4.339,0-7.76-1.645-10.262-4.935
		c-2.221-2.906-3.33-6.66-3.33-11.261c0-5.247,1.423-9.395,4.269-12.442c2.401-2.583,5.398-3.875,8.99-3.875
		c4.097,0,7.356,1.585,9.778,4.753c2.159,2.825,3.239,6.428,3.239,10.808V284.7z M634.938,281.794
		c-0.283-3.593-1.332-6.316-3.148-8.174c-1.554-1.594-3.542-2.392-5.964-2.392c-2.784,0-5.045,1.08-6.78,3.239
		c-1.534,1.877-2.412,4.319-2.634,7.326H634.938z"
      />
      <path
        :fill="subTitleColor"
        d="M654.011,293.751v6.146l-3.36,8.446h-2.604l3.118-8.446h-3.36v-6.146H654.011z"
      />
      <path
        :fill="subTitleColor"
        d="M705.443,277.011l-3.784,1.12c-1.11-4.561-3.733-6.842-7.871-6.842c-3.047,0-5.398,1.292-7.053,3.875
		c-1.453,2.301-2.18,5.358-2.18,9.173c0,4.439,0.948,7.83,2.846,10.171c1.614,1.998,3.743,2.997,6.387,2.997
		c4.359,0,7.094-2.32,8.204-6.963l3.693,1.241c-1.938,5.914-5.903,8.87-11.897,8.87c-4.278,0-7.618-1.645-10.02-4.935
		c-2.119-2.886-3.179-6.64-3.179-11.261c0-5.288,1.382-9.435,4.147-12.442c2.381-2.583,5.398-3.875,9.051-3.875
		c3.048,0,5.601,0.848,7.659,2.543C703.324,272.198,704.656,274.307,705.443,277.011z"
      />
      <path
        :fill="subTitleColor"
        d="M725.968,268.141c4.138,0,7.427,1.635,9.869,4.904c2.18,2.927,3.27,6.67,3.27,11.231
		c0,5.287-1.403,9.444-4.208,12.472c-2.382,2.604-5.379,3.905-8.991,3.905c-4.197,0-7.507-1.654-9.929-4.965
		c-2.14-2.906-3.209-6.649-3.209-11.23c0-5.288,1.393-9.435,4.178-12.442C719.328,269.433,722.336,268.141,725.968,268.141z
		 M725.968,271.289c-3.067,0-5.418,1.292-7.053,3.875c-1.453,2.28-2.18,5.338-2.18,9.173c0,4.439,0.928,7.83,2.785,10.171
		c1.614,1.998,3.743,2.997,6.387,2.997c3.048,0,5.398-1.302,7.054-3.905c1.453-2.301,2.18-5.389,2.18-9.263
		c0-4.38-0.929-7.729-2.785-10.051C730.741,272.288,728.612,271.289,725.968,271.289z"
      />
      <path
        :fill="subTitleColor"
        d="M773.102,253.883v46.014h-3.724v-4.783c-2.301,3.693-5.419,5.54-9.354,5.54
		c-3.895,0-7.003-1.645-9.323-4.935c-2.1-2.967-3.148-6.781-3.148-11.442c0-5.308,1.321-9.445,3.966-12.412
		c2.199-2.482,4.975-3.724,8.324-3.724c3.875,0,7.014,1.807,9.415,5.419v-19.677H773.102z M769.258,277.707
		c-2.18-4.137-5.116-6.206-8.81-6.206c-2.865,0-5.105,1.252-6.72,3.754c-1.474,2.261-2.21,5.288-2.21,9.082
		c0,4.379,0.999,7.739,2.997,10.08c1.635,1.958,3.713,2.937,6.235,2.937c3.391,0,6.227-2.008,8.507-6.024V277.707z"
      />
      <path
        :fill="subTitleColor"
        d="M809.672,284.7H787.18c0,4.339,1.049,7.679,3.148,10.02c1.756,1.938,3.996,2.906,6.72,2.906
		c4.46,0,7.387-2.22,8.779-6.66l3.603,1.362c-2.14,5.551-6.287,8.325-12.442,8.325c-4.339,0-7.76-1.645-10.262-4.935
		c-2.221-2.906-3.33-6.66-3.33-11.261c0-5.247,1.423-9.395,4.269-12.442c2.401-2.583,5.398-3.875,8.99-3.875
		c4.097,0,7.356,1.585,9.778,4.753c2.159,2.825,3.239,6.428,3.239,10.808V284.7z M805.827,281.794
		c-0.283-3.593-1.332-6.316-3.148-8.174c-1.554-1.594-3.542-2.392-5.964-2.392c-2.784,0-5.045,1.08-6.78,3.239
		c-1.534,1.877-2.412,4.319-2.634,7.326H805.827z"
      />
      <path
        :fill="subTitleColor"
        d="M836.796,276.103c1.856-5.308,5.923-7.962,12.199-7.962c7.326,0,10.989,3.432,10.989,10.293v10.928
		c0,4.138,0.252,7.649,0.757,10.535h-3.603c-0.263-1.19-0.494-3.007-0.696-5.449c-2.725,4.138-6.458,6.206-11.2,6.206
		c-3.29,0-5.803-0.948-7.538-2.846c-1.474-1.594-2.21-3.643-2.21-6.146c0-5.61,3.491-9.001,10.475-10.171
		c2.724-0.465,6.154-0.797,10.292-0.999v-1.665c0-2.946-0.646-4.995-1.938-6.146c-1.13-1.009-2.926-1.514-5.389-1.514
		c-4.439,0-7.285,2.079-8.536,6.236L836.796,276.103z M856.261,283.338c-3.108,0.061-6.267,0.394-9.476,0.999
		c-4.964,1.009-7.446,3.39-7.446,7.144c0,2.019,0.646,3.572,1.938,4.662c1.15,0.989,2.694,1.483,4.632,1.483
		c4.157,0,7.607-2.24,10.353-6.721V283.338z"
      />
      <path
        :fill="subTitleColor"
        d="M875.999,268.898v5.752c3.471-4.339,7.245-6.509,11.321-6.509c2.806,0,4.995,0.878,6.569,2.634
		c1.412,1.555,2.119,3.593,2.119,6.115v23.007h-3.905v-21.402c0-4.581-1.917-6.872-5.752-6.872c-3.592,0-7.003,2.149-10.231,6.448
		v21.826h-3.845v-30.999H875.999z"
      />
      <path
        :fill="subTitleColor"
        d="M930.126,268.898l-11.716,32.604c-1.312,3.713-2.946,6.307-4.903,7.78c-1.615,1.23-3.644,1.847-6.085,1.847
		c-1.029,0-1.958-0.081-2.785-0.242l-0.303-3.36c1.109,0.242,2.139,0.363,3.088,0.363c1.896,0,3.44-0.616,4.632-1.847
		c1.069-1.07,2.048-2.806,2.937-5.207l0.575-1.483l-11.05-30.454h4.147l6.721,19.979c0.403,1.231,1.019,3.311,1.847,6.236h0.302
		c0.303-0.867,0.677-2.018,1.12-3.451c0.465-1.433,0.777-2.411,0.938-2.937l6.569-19.828H930.126z"
      />
      <path
        :fill="subTitleColor"
        d="M978.259,268.898l-9.384,30.999h-4.753l-5.207-19.162c-0.666-2.442-1.353-5.358-2.059-8.749h-0.696
		c-0.868,4.157-1.523,7.074-1.968,8.749l-5.207,19.162h-4.782l-9.233-30.999h4.147l5.086,19.283
		c0.787,3.068,1.493,5.964,2.119,8.688h0.666c1.291-5.59,1.957-8.466,1.997-8.628l5.146-19.344h4.965l5.146,19.102
		c0.443,1.655,1.1,4.612,1.968,8.87h0.696c0.706-3.289,1.423-6.226,2.149-8.81l5.268-19.162H978.259z"
      />
      <path
        :fill="subTitleColor"
        d="M985.978,276.103c1.856-5.308,5.923-7.962,12.199-7.962c7.326,0,10.989,3.432,10.989,10.293v10.928
		c0,4.138,0.252,7.649,0.757,10.535h-3.603c-0.263-1.19-0.494-3.007-0.696-5.449c-2.725,4.138-6.458,6.206-11.2,6.206
		c-3.29,0-5.803-0.948-7.538-2.846c-1.474-1.594-2.21-3.643-2.21-6.146c0-5.61,3.491-9.001,10.475-10.171
		c2.724-0.465,6.154-0.797,10.292-0.999v-1.665c0-2.946-0.646-4.995-1.938-6.146c-1.13-1.009-2.926-1.514-5.389-1.514
		c-4.439,0-7.285,2.079-8.536,6.236L985.978,276.103z M1005.443,283.338c-3.108,0.061-6.267,0.394-9.476,0.999
		c-4.964,1.009-7.446,3.39-7.446,7.144c0,2.019,0.646,3.572,1.938,4.662c1.15,0.989,2.694,1.483,4.632,1.483
		c4.157,0,7.607-2.24,10.353-6.721V283.338z"
      />
      <path
        :fill="subTitleColor"
        d="M1035.534,268.141l-0.303,3.845c-2.18,0-4.197,0.938-6.054,2.815c-1.736,1.735-3.048,4.047-3.936,6.933
		v18.163h-3.784v-30.999h3.663v8.053c1.211-3.088,2.846-5.398,4.904-6.933C1031.72,268.767,1033.557,268.141,1035.534,268.141z"
      />
      <path
        :fill="subTitleColor"
        d="M1068.289,284.7h-22.492c0,4.339,1.049,7.679,3.148,10.02c1.756,1.938,3.996,2.906,6.72,2.906
		c4.46,0,7.387-2.22,8.779-6.66l3.603,1.362c-2.14,5.551-6.287,8.325-12.442,8.325c-4.339,0-7.76-1.645-10.262-4.935
		c-2.221-2.906-3.33-6.66-3.33-11.261c0-5.247,1.423-9.395,4.269-12.442c2.401-2.583,5.398-3.875,8.99-3.875
		c4.097,0,7.356,1.585,9.778,4.753c2.159,2.825,3.239,6.428,3.239,10.808V284.7z M1064.444,281.794
		c-0.283-3.593-1.332-6.316-3.148-8.174c-1.554-1.594-3.542-2.392-5.964-2.392c-2.784,0-5.045,1.08-6.78,3.239
		c-1.534,1.877-2.412,4.319-2.634,7.326H1064.444z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: 'ligth'
    }
  },
  computed: {
    titleColor() {
      return this.theme === 'dark' ? '#FFFFFF' : '#4C4C4C'
    },
    subTitleColor() {
      return this.theme === 'dark' ? '#FFFFFF' : '#3F3F3F'
    },
    primary() {
      return '#1F89E5'
    },
    secondary() {
      return '#4051B5'
    },
    accent() {
      return '#FF6333'
    }
  }
}
</script>
